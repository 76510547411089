
	var sm_width = 767,
		md_width = 992,
		lg_width = 1200,
		property_item_margin_bottom = 30,
		sm_column_amount = 0,
		md_column_amount = 0,
		house_and_land = false,
		readybuilt = false,
		display_for_sale = false,
		search = false;

	$(document).ready(function(){
		//console.log($('body.new-homes_series #homes-on-display'));
		if($('body.house-and-land-landing-page').length > 0 || $('body.house-and-land').length > 0 ||  $('body.new-homes_series #homes-on-display').length > 0){
			 house_and_land = true;
		}
		if($('body.readybuilt-landing').length > 0 || $('body.readybuilt').length > 0){
			 readybuilt = true;
		}
		if($('body.display-for-sale_detail').length > 0 || $('body.display-for-sale').length > 0){
			 display_for_sale = true;
		}
		if ($('body.search').length > 0) {
			search = true;
		}

		if($('.property__view-more').length){
			// ---------------- ini variables ----------------
			var property_class = $(".property").attr("class");
			sm_column_amount = 12 / parseInt(property_class.match(/col-sm-\d/)[0].match(/[0-9]+/g)[0]),
			md_column_amount = 12 / parseInt(property_class.match(/col-md-\d/)[0].match(/[0-9]+/g)[0]);

			if($('.property-item').length)
			property_item_margin_bottom = parseInt($('.property-item').css("margin-bottom").replace("px",""));
			// ------------------------------------------------

			$(".property__view-more .bottom .slick-container").slick({
				prevArrow: '<img src="/resources/images/slick-arrow-view-more.png" class="slick-arrow prev" alt="" />',
				nextArrow: '<img src="/resources/images/slick-arrow-view-more.png" class="slick-arrow next" alt="" />'
			});


			$('main').on('click',".property .slide-down-preview" , function(e){
				e.preventDefault();
				//ini variables
				var button = $(this),
					parent = button.parents('.property'),
					page_id = parent.data("id"),
					home_id = parent.data("home-id"),
					href = parent.data("href");


				//close all if not closing self
				if(button.hasClass("active")){
					close_view_more_desktop(button);
				}else if(button.hasClass("ajaxed")){
					open_view_more_desktop(button);
				}else{
					button.text("loading...");

					if ($(this).parents('.property').hasClass('grid_hl')) {
						house_and_land = true;
						readybuilt = false;
					}
					else if ($(this).parents('.property').hasClass('grid_rb')) {
						house_and_land = false;
						readybuilt = true;
					}

					var ajax_file = "";
					if(house_and_land){
						ajax_file = "house-and-land__preview";
					}
					else if (readybuilt) {
						ajax_file = 'readybuilt__preview';
					}
					else if (display_for_sale) {
						ajax_file = 'display-for-sale__preview';
					}
					else{
						ajax_file = "new-homes__preview";
					}

					if (search) {
						$buttonProperty = button.parents('.property');
						if ($buttonProperty.hasClass('hl-search-result')) {
							if ($buttonProperty.hasClass('rb-search-result')) ajax_file = 'readybuilt__preview_search';
							else ajax_file = 'house-and-land__preview_search';
						}
					}

					$.ajax({
						type: 'POST',
						url: '/pages/components/'+ajax_file+'.php',
						data:({page_id:page_id,href:href,home_id:home_id}),
						dataType: 'html',
						success: function(html) {

							var	data = ini_viewmore_variables(button),
								property_div = data.property_div,
								id = data.id,
								view_more_div = data.view_more_div,
								view_more_height = data.view_more_height,
								window_width = data.window_width;
							view_more_div.html(html);

							var svg_object = view_more_div.find("object"),
								svg_id = svg_object.attr('id');

							var svg = document.getElementById(svg_id);

							open_view_more_desktop(button, data);
							button.addClass("ajaxed");

						}
					});
				}

				return false;
			});

			$(".main-part, .section-info, .display-centre-single").on('click',".close_view-more",function(e){

				var button = $('.property-item__tail___preview-btn.active');
					close_view_more_desktop(button);
			});

			var window_width_resize = $(window).width();


			$(window).resize(function(){

				//had to hide all because SVG event listener will reopen the wrong preview
				if($(window).width() < sm_width){
					close_all_previews();
					$('.property__view-more').html("");
					$('.property-item__tail___preview-btn').removeClass("ajaxed");
				}


				if(
					(window_width_resize > md_width && $(window).width() < md_width) ||
					(window_width_resize < md_width && window_width_resize > sm_width && $(window).width() > md_width) ||
					(window_width_resize < sm_width && $(window).width() > sm_width)
				  ){

					window_width_resize = $(window).width();
					resize_product_previews();

					}

				check_viewmore_box_positon();
				property_item_margin_bottom = parseInt($('.property-item').css("margin-bottom").replace("px",""));

				waitForFinalEvent(function(){
			      check_viewmore_box_positon();
			    }, 200, "check padding");
			});

		}
	});

	//----------------- functions --------------------//
		function ini_viewmore_variables(button){
			var property_div = button.parents('.property'),
				id = property_div.data("id"),
				view_more_div = property_div.next('.property__view-more[data-id='+id+']'),
				view_more_height = view_more_div.height(),
				window_width = $(window).width();
			return {
				property_div 	 : property_div,
				id 				 : id,
				view_more_div	 : view_more_div,
				view_more_height : view_more_height,
				window_width 	 : window_width
			};
		}

		/*
		function ini_svg(svg_id, view_more_div){
			var svgContainer = document.getElementById(svg_id);
			if (typeof svgContainer != 'undefined') {
				var svgDoc = svgContainer.contentDocument;
				if (svgDoc != null) {
					if (typeof svgDoc == 'object') {
						hl_exec = false;
						if (house_and_land == true) hl_exec = true;
						else if ($('#' + svg_id).attr('rel') == 'search') hl_exec = true;

						if(hl_exec == true){
							var svg_object = $("#"+svg_id);
							$(svgDoc).find("g").css('display','none');
							if (svg_object.attr('data-layer-id')) {
									var layerID = svg_object.attr('data-layer-id').replace(/_/g, '_x5F_');
									$(svgDoc).find("#"+layerID).css('display','block');
								}
							$('.design__image').css('visibility', 'visible');

						}else{
							$(view_more_div).find('.design__select').children("span").each(function() {
								var $aTag = $(this);

								if (!$aTag.hasClass('active') && $aTag.attr('data-layer-id')) {
									var layerID = $aTag.attr('data-layer-id').replace(/_/g, '_x5F_');
									var svgLayer = svgDoc.getElementById(layerID);
									if(svgLayer){
										svgLayer.setAttribute('display', 'none');
									}

								}
								$('.design__image').css('visibility', 'visible');
								$aTag.removeClass('disabled');
							});
						}
					}
				}
			}
		}
		*/
		$(".property__view-more").on('click',".newhome_button",function(e){
			var pageID = $(this).attr('data-id');
			var parent_div = $(this).parents(".property__view-more");

			parent_div.find('.bottom .floorplan.active').removeClass('active');
			if ($('html').hasClass('ie') || $('html').hasClass('firefox')) {
				$('#floorplan-' + pageID).addClass('active');
			}
			else {
				$('#floorplan-' + pageID).addClass('active').css('opacity', 0);
			}

			parent_div.find('.newhome_button').removeClass('active');
			$(this).addClass("active");

			parent_div.find('.top .home_info').removeClass('active');
			$('.top  .home_info[data-id='+$(this).data("id")+']').addClass('active');

			return false;
		});


		function resize_product_previews(){
			if($(".property__view-more.active").length){
				if($(window).width() > sm_width){

					$('.property').css('padding-bottom',"0px");

					var button = $(".property-item__tail___preview-btn.active"),
						data = ini_viewmore_variables(button),
						property_div = data.property_div,
						id = data.id,
						view_more_div = data.view_more_div,
						view_more_height = data.view_more_height,
						window_width = data.window_width,
						padding_bottom_divs = get_divs_for_padding(property_div);


					$.each(padding_bottom_divs ,function(){
						$(this).css('padding-bottom', (view_more_height + property_item_margin_bottom) + "px");

					});

				}else{
					//console.log("close");
					//close_all_previews();
				}
			}
		}
		function check_viewmore_box_positon(){

			if($(".property__view-more.active").length){

				var button = $(".property-item__tail___preview-btn.active"),
					data = ini_viewmore_variables(button),
					property_div = data.property_div,
					id = data.id,
					view_more_div = data.view_more_div,
					view_more_height = data.view_more_height,
					window_width = data.window_width;

				view_more_div.css("top", (property_div.position().top + property_div.height()) + "px");

				$(window).scrollTop(property_div.offset().top - property_item_margin_bottom);

			}
		}


		function close_all_previews(){

			if($(".property__view-more.active").length){

				$(".property-item__tail___preview-btn").text('Preview +').removeClass('active');
				$(".property__view-more").css('opacity','0').removeClass('active').removeClass('loading');
				$(".property").removeClass('active');
				$('.property').css('padding-bottom',"0px");
			}


		}

		function get_divs_for_padding(property_div){
			var $return_array = [],
				property_within_parent_row = property_div.parents(".row").find(".property"),
				property_index = property_within_parent_row.index(property_div),
				temp_number_columns = 0;

				var property_class = $(property_within_parent_row[0]).attr("class");

				if ($(window).width() >= lg_width && property_class.indexOf('col-lg') >= 0) {
					temp_number_columns = 12 / parseInt(property_class.match(/col-lg-\d/)[0].match(/[0-9]+/g)[0]);
				}
				else if($(window).width() > md_width){
					temp_number_columns = 12 / parseInt(property_class.match(/col-md-\d/)[0].match(/[0-9]+/g)[0]);
				}else{
					temp_number_columns = 12 / parseInt(property_class.match(/col-sm-\d/)[0].match(/[0-9]+/g)[0]);
				}

				//console.log(property_within_parent_row,property_index);

				if(temp_number_columns == 4){

					if(property_index % 4 == 0){
						//1st
						$return_array.push(property_div,property_within_parent_row[property_index + 1],property_within_parent_row[property_index +2],property_within_parent_row[property_index + 3]);
					}else if(property_index % 4 == 1){
						//2nd
						$return_array.push(property_within_parent_row[property_index - 1],property_div,property_within_parent_row[property_index + 1],property_within_parent_row[property_index +2]);
					}else if(property_index % 4 == 2){
						//3rd
						$return_array.push(property_within_parent_row[property_index - 2],property_within_parent_row[property_index - 1],property_div,property_within_parent_row[property_index + 1]);
					}else{
						//4th
						$return_array.push(property_within_parent_row[property_index - 3],property_within_parent_row[property_index - 2],property_within_parent_row[property_index - 1],property_div);
					}

				}else if(temp_number_columns == 3){

					if(property_index % 3 == 0){
						//1st
						$return_array.push(property_div,property_within_parent_row[property_index + 1],property_within_parent_row[property_index +2]);
					}else if(property_index % 3 == 1){
						//2nd
						$return_array.push(property_within_parent_row[property_index - 1],property_div,property_within_parent_row[property_index + 1]);
					}else{
						//3rd
						$return_array.push(property_within_parent_row[property_index - 2],property_within_parent_row[property_index - 1],property_div);
					}

				}else if(temp_number_columns == 2){

					if(property_index % 2 == 0){
						$return_array.push(property_div,property_within_parent_row[property_index + 1]);
					}else{
						$return_array.push(property_within_parent_row[property_index - 1],property_div);
					}
				}


			return $return_array;
		}



		function close_view_more_desktop(button, data){
			if(!button.hasClass("loading")){
				button.addClass("loading");

				if($.isEmptyObject(data)){
					var data = ini_viewmore_variables(button);
				}

				var	property_div = data.property_div,
					id = data.id,
					view_more_div = data.view_more_div,
					view_more_height = data.view_more_height,
					window_width = data.window_width,
					padding_bottom_divs = get_divs_for_padding(property_div);



				button.text('Preview +');

				$.each(padding_bottom_divs ,function(){
					if($(this).hasClass('property')){
						$(this).animate({ paddingBottom: "0px" }, 500);
					}
				});

				view_more_div.animate({  height: "0px" }, 500, function() {
			    	view_more_div.css('opacity','0').css('height', view_more_height);
			    	button.removeClass("loading");
			  	});

			  	property_div.toggleClass('active');
				button.toggleClass('active');
				view_more_div.toggleClass('active');
			}

		}

		function open_view_more_desktop(button, data){

			if(!button.hasClass("loading") && $(window).width() > sm_width){
				button.addClass("loading");

				if($.isEmptyObject(data)){
					var data = ini_viewmore_variables(button);
				}

				var	property_div = data.property_div,
					id = data.id,
					view_more_div = data.view_more_div,
					view_more_height = data.view_more_height,
					window_width = data.window_width,
					padding_bottom_divs = get_divs_for_padding(property_div);

				if(!button.hasClass("active")){
					close_all_previews();
				}


				button.text('Close -');

				view_more_div.css("display","block").css('height','0px').css('opacity','1').css("top", (property_div.position().top + property_div.height()) + "px");

				//console.log(property_div);



				$.each(padding_bottom_divs ,function(){
					// console.log((view_more_height + property_item_margin_bottom));

					if($(this).hasClass('property')){
						$(this).animate({
							paddingBottom: (view_more_height + property_item_margin_bottom) +"px" }, 500,
							function() { button.removeClass("loading");  });
					}

				});


				view_more_div.animate({  height: view_more_height   }, 500, function(){
					if($(window).width() < md_width){
						$("html, body").animate({scrollTop:view_more_div.offset().top - 100}, '300');

					}
				});



				property_div.toggleClass('active');
				button.toggleClass('active');
				view_more_div.toggleClass('active');

				check_new_homes_filter_results();
			}else{
				//console.log("nooooo");
			}
		}


		function check_new_homes_filter_results(){
			/*
			if($("body").hasClass('new-homes')){
				var design_ids = $("#design_ids__holder").val(),
					view_more_div = $('.all_homes .property__view-more.active'),
					grey_ids = "";

				$('.all_homes .property__view-more.active .design__select span').each(function(){
					var design_id = $(this).data("id"),
						str = "|" + design_id + "|";

					// console.log(str, design_ids.indexOf(str));
					if(design_ids.indexOf(str) < 0){
						$(this).addClass('grey');

						if(grey_ids != ""){
							grey_ids += "," + $(this).text();
						}else{
							grey_ids += $(this).text();
						}




					}else{
						$(this).removeClass('grey');
					}

				});
				//console.log(view_more_div,grey_ids);
				view_more_div.attr("data-grey-ids",grey_ids);
			}
			*/
		}

		var waitForFinalEvent = (function () {
		  	var timers = {};
		  	return function (callback, ms, uniqueId) {
			    if (!uniqueId) {
			      uniqueId = "Don't call this twice without a uniqueId";
			    }
			    if (timers[uniqueId]) {
			      clearTimeout (timers[uniqueId]);
			    }
				timers[uniqueId] = setTimeout(callback, ms);
		  	};
		})();


		function getQueryVariable(url, variable) {

		  	var query = url.substr(url.indexOf("?") + 1);
		    var vars = query.split('&');
		    //console.log(vars);
		    for (var i=0; i<vars.length; i++) {
		        var pair = vars[i].split('=');
		        if (pair[0] == variable) {
		            return pair[1];
		        }
		     }
		    return false;
		  }


