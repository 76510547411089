function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name,"",-1);
}


var myCookie = readCookie('popup');
		
if (myCookie == 'seen') {
	
	$('.rebrand-info').hide();
	$('.close-rebrand').hide();
	
}else{
		
	$('.rebrand-info').show();
	$('.close-rebrand').show();

}

$('.popup .close-btn').on('click', function(e) {
	  
	  e.preventDefault();
	  $('.popup').fadeOut();
	  
});

$('.fixed-rebrand .close-rebrand').on('click', function(e) {
	  
	  e.preventDefault();
	  $('.rebrand-info').fadeOut();
	  $('.close-rebrand').fadeOut();
	  
	  createCookie('popup', 'seen', 1);
	  
});




$('.fixed-rebrand .activate-popup, .fixed-rebrand .open_popup').on('click', function(e) {
	e.preventDefault();
	$('.popup').fadeIn();
});
